.center {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.center-form-item {
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
}

.responsive-side-popover {
  padding-left: 0px;
  .ant-popover-inner-content {
    padding: 0px;
  }
}

.remove-space-form-item {
  margin-bottom: 4px;
  .ant-form-item-label {
    padding-bottom: 0px;
  }
}