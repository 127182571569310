@import "~antd/dist/antd.less";
@import "./utils.less";
@import "./scroll.less";
@import "./fonts.less";
// @import "~tailwindcss/base.css";
// @import "~tailwindcss/components.css";
@import "~tailwindcss/utilities.css";
@import "./overide-antd.less";

html * {
  font-family: "FCIconic";
}

body {
  font-size: 14px;
}

html,
body,
#root {
  min-height: 100vh;
  min-width: 100vw;
  overflow: hidden;
}
body {
  margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
.ant-typography * {
  margin: 0px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 100000s ease-in-out 0s;
  // transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

.ant-dropdown-menu-root {
  overflow: hidden;
}

@primary-color: #2F9A5D;@border-radius: 1rem;@border-radius-base: 1rem;@btn-border-radius-base: 1rem;@control-border-radius: 1rem;