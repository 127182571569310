.ant-empty {
    font-size: 20px;
}

.ant-form-item-label {
    label {
        font-size: 20px;
    }
}

.ant-form-item-explain-error {
    font-size: 20px;
}

.ant-input {
    font-size: 20px;
}

.ant-select-selector {
    font-size: 20px;
}
.ant-select-item {
    font-size: 20px;
    line-height: 22px;
}

.ant-form label {
    font-size: 20px;
}
.ant-radio-inner {
    width: 20px;
    height: 20px;
}

.ant-picker {
    font-size: 20px;
}
.ant-picker-input>input {
    font-size: 20px;
}
.ant-picker-dropdown {
    font-size: 20px;
}

.ant-picker-header>button{
    font-size: 20px;
}

.ant-btn-sm {
    height: 30px;
    font-size: 20px;
}

.ant-checkbox-inner {
    width: 20px;
    height: 20px;
}

.ant-segmented-item-label {
    font-size: 20px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    font-size: 20px;
}

.ant-tooltip-inner {
    font-size: 20px;
}

.ant-modal-title {
    font-size: 22px;
}

.ant-typography {
    font-size: 20px;
}

h1.ant-typography,
div.ant-typography-h1,
div.ant-typography-h1>textarea,
.ant-typography h1 {
    font-size: 42px;
}

h2.ant-typography,
div.ant-typography-h2,
div.ant-typography-h2>textarea,
.ant-typography h2 {
    font-size: 36px;
}

h3.ant-typography,
div.ant-typography-h3,
div.ant-typography-h3>textarea,
.ant-typography h3 {
    font-size: 30px;
}

h4.ant-typography,
div.ant-typography-h4,
div.ant-typography-h4>textarea,
.ant-typography h4 {
    font-size: 26px;
}

h5.ant-typography,
div.ant-typography-h5,
div.ant-typography-h5>textarea,
.ant-typography h5 {
    font-size: 20px !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    font-size: 18px;
}

.ant-badge-status-dot {
    width: 12px;
    height: 12px;
    top: -3;
}
.ant-badge-status-text {
    font-size: 20px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: 22px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
    font-size: 20px;
}

.ant-popover-message {
    font-size: 20px;
}
.ant-popover-message>.anticon {
    font-size: 20px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    padding: 3px 5px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    height: 30px;
}

.ant-select-arrow {
    height: 18px;
}

.ant-picker-calendar-header .ant-picker-calendar-month-select {
    display: flex;
    align-items: flex-end;
}
.ant-picker-calendar-header .ant-picker-calendar-year-select {
    display: flex;
    align-items: flex-end;
}
.ant-radio-button-wrapper {
    font-size: 20px;
}
.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    display: flex;
}
.ant-radio-group-small .ant-radio-button-wrapper {
    height: 30px;
    padding: 3px 5px;
}
.ant-picker-calendar {
    font-size: 20px;
}