@font-face {
  font-family: "FCIconic";
  // src: url("../fonts/FC_Iconic_Regular.ttf");
  src: url("../fonts/Kanit_Regular.ttf");
  font-display: fallback;
}

// @font-face {
//   font-family: "FCIconic";
//   src: url("../fonts/FC_Iconic_Bold.ttf");
//   font-display: fallback;
//   font-weight: bold;
//   font-style: normal;
// }

// @font-face {
//   font-family: "FCIconic";
//   src: url("../fonts/FC_Iconic_Italic.ttf");
//   font-display: fallback;
//   font-weight: normal;
//   font-style: italic;
// }

// @font-face {
//   font-family: "FCIconic";
//   src: url("../fonts/FC_Iconic_Bold_Italic.ttf");
//   font-display: fallback;
//   font-weight: bold;
//   font-style: italic;
// }
